// Tufte
@import "tufte";

// Additional styles for Hugo
@import "general";

/// Pages
@import "pages/footer";

/// Components
@import "components/code-highlight";
@import "components/toc";
@import "components/nav";
@import "components/brand";
@import "components/meta";
@import "components/sharing-buttons";

@import "print";

// Look at this https://gohugo.io/hugo-pipes/resource-from-template/#readout

@import "syntax/highlight-dark.scss"

