@media print {
  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  h2,
  h3 {
    page-break-before: avoid;
    page-break-after: avoid;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  article {
    max-width: 100%;
  }

  .sidenote,
  .marginnote {
    // position: absolute;
    // top: 0;
    // right: 0;
  }

  hr,
  nav,
  footer,
  body .js-toggle-wrapper {
    display: none;
  }
}
