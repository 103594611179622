$ht-code-border-radius: 0.4em;
.highlight {
  width: 55%;
  overflow-x: scroll;
  // border-radius: $ht-code-border-radius;
  margin-top: 1.4em;
  margin-bottom: 1.4em;
  margin-right: 2.5%;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  code {
    font-size: 1rem;
    display: block;
  }

  & > .chroma {
    margin: 0;
  }

  & > .chroma .hl {
    // border-radius: $ht-code-border-radius / 2;
    display: block;

    &::before {
      content: "> ";
      // font-family: "Noto Emoji";
      font-family: inherit;
      position: absolute;
      left: 0;
      color: #111;
    }
  }

  & > div.chroma > table.lntable {
    overflow: initial;
    // margin: $ht-code-border-radius 0 $ht-code-border-radius 0;
    & td:first-of-type {
      & span:not(& span > span) {
        padding: 0 0.75em 0 0.5em;
      }
    }

    pre {
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
    }
  }

  & > .chroma > code {
    width: max-content;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: 0.5em;

    & > span.hl {
      margin-left: -0.5em;
      padding-left: 0.5em;
    }
  }
}

@media screen and (max-width: 760px) {
  .highlight {
    width: 90%;
  }
}

// fix #48 => https://github.com/slashformotion/hugo-tufte/issues/48
ul > li > div.highlight {
  width: 100%;
}
